import React, { useState } from 'react';
import { useCallback } from "react";
import { loadSlim } from "tsparticles-slim";
import Particles from 'react-particles';
import DHRCover from './components/DHRCover';
import Initbio from './components/Initbio';
// import ThirdSession from './components/not-in-use/ThirdSection';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { Fade } from 'react-awesome-reveal';
import GlobalContext from './context/GloblalContext';
// import Trajetory from './components/Trajetory';
import Endpoint from './components/Endpoint';
import DLBiography from './components/DLBiography';

function App() {
    const [themeColor, setThemeColor] = useState(localStorage.getItem('hs_theme') || 'light');

    function toggleTheme() {
        setThemeColor(themeColor === 'light' ? 'light' : 'dark');
    }

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (
        <BrowserRouter
        // basename={process.env.PUBLIC_URL}
        >
            <Routes>
            <Route
                    path="/"
                    element={
                        <>
                        <Particles
                                        id="tsparticles"
                                        init={particlesInit}
                                        loaded={particlesLoaded}
                                        zIndex={50}
                                        options={{
                                            background: {
                                                color: {
                                                    value: "none",
                                                },
                                            },
                                            fpsLimit: 120,
                                            interactivity: {
                                                events: {
                                                    onClick: {
                                                        enable: false,
                                                        mode: "push",
                                                    },
                                                    onHover: {
                                                        enable: false,
                                                        mode: "repulse",
                                                    },
                                                    resize: true,
                                                },
                                                modes: {
                                                    push: {
                                                        quantity: 2,
                                                    },
                                                    repulse: {
                                                        distance: 100,
                                                        duration: 0.9,
                                                    },
                                                },
                                            },
                                            particles: {
                                                color: {
                                                    value: "#ffffff",
                                                },
                                                links: {
                                                    color: "#ffffff",
                                                    distance: 200,
                                                    enable: true,
                                                    opacity: 0.05,
                                                    width: 1,
                                                },
                                                move: {
                                                    direction: "none",
                                                    enable: true,
                                                    outModes: {
                                                        default: "bounce",
                                                    },
                                                    random: false,
                                                    speed: 1,
                                                    straight: false,
                                                },
                                                number: {
                                                    density: {
                                                        enable: true,
                                                        area: 800,
                                                    },
                                                    value: 80,
                                                },
                                                opacity: {
                                                    value: 0.05,
                                                },
                                                shape: {
                                                    type: "circle",
                                                },
                                                size: {
                                                    value: { min: 1, max: 5 },
                                                },
                                            },
                                            detectRetina: true,
                                        }}
                                    />
                        <DHRCover />
                        </> }
                        />
                <Route
                    path="/dlbiography"
                    element={
                        <>
                            <GlobalContext.Provider value={{ theme: themeColor, toggleTheme }}>
                                {/* <Fade duration={2000}> */}
                                    <Particles
                                        id="tsparticles"
                                        init={particlesInit}
                                        loaded={particlesLoaded}
                                        zIndex={50}
                                        options={{
                                            background: {
                                                color: {
                                                    value: "none",
                                                },
                                            },
                                            fpsLimit: 120,
                                            interactivity: {
                                                events: {
                                                    onClick: {
                                                        enable: false,
                                                        mode: "push",
                                                    },
                                                    onHover: {
                                                        enable: false,
                                                        mode: "repulse",
                                                    },
                                                    resize: true,
                                                },
                                                modes: {
                                                    push: {
                                                        quantity: 2,
                                                    },
                                                    repulse: {
                                                        distance: 100,
                                                        duration: 0.9,
                                                    },
                                                },
                                            },
                                            particles: {
                                                color: {
                                                    value: "#ffffff",
                                                },
                                                links: {
                                                    color: "#ffffff",
                                                    distance: 200,
                                                    enable: true,
                                                    opacity: 0.05,
                                                    width: 1,
                                                },
                                                move: {
                                                    direction: "none",
                                                    enable: true,
                                                    outModes: {
                                                        default: "bounce",
                                                    },
                                                    random: false,
                                                    speed: 1,
                                                    straight: false,
                                                },
                                                number: {
                                                    density: {
                                                        enable: true,
                                                        area: 800,
                                                    },
                                                    value: 80,
                                                },
                                                opacity: {
                                                    value: 0.05,
                                                },
                                                shape: {
                                                    type: "circle",
                                                },
                                                size: {
                                                    value: { min: 1, max: 5 },
                                                },
                                            },
                                            detectRetina: true,
                                        }}
                                    />
                                    <DLBiography />
                                    <hr className='border-sub-blue2' />
                                    <Initbio />
                                    <hr className='border-sub-blue2' />
                                    {/* <Trajetory /> */}
                                    {/* <hr className='border-sub-blue2' /> */}
                                    <Endpoint />
                                    {/* <hr className='border-sub-blue2' /> */}
                                    {/* Components que vão utilizar o Background das particulas */}
                                {/* </Fade> */}
                                {/* Components que vão utilizar o Background das particulas sem o Fade */}
                            </GlobalContext.Provider>
                        </>
                    }
                />
                {/* Other components, need route exact path="/any" */}
                {/* <Route path="/dl-bio" element={<Initbio />} /> */}

                {/* Other components, need route */}
                {/* <ThirdSession /> */}
                {/* <hr className='dark:border-sub-blue' /> */}
            </Routes>

        </BrowserRouter>
    );
};
export default App;
