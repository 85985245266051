import React from "react";
import "../css/Cover.css";

function Initbio() {

  return (
    <>
      <div className="flex flex-row z-10 py-8 sm:py-20 mm:h-full sm:h-full md:h-full lg:h-full xl:h-full mm:py-6 md:py-0 mm:flex mx-auto overflow-hidden px-2 sm:px-6 lg:px-8 css-selector bg-gradient-to-t bg-sub-black2 bg-center ">
        <div class="max-w-[100rem] pt-20 mx-auto px-4 mm:px-0 sm:px-6 lg:px-8 mm:pt-0 mm:m-auto">
          <div class="grid md:grid-cols-1 mm:gap-0 gap-4 md:gap-6 xl:gap-10 md:items-center justify-items-end p-4 mm:p-0 rounded-xl mm:mt-5 mm:mb-5  mt-20 mb-20">
            <div class="left-to-right mm:order-2 shadow-2x1 shadow-slate-900/50  dark:shadow-2x1 dark:shadow-slate-900 sm:m-auto">
              <span class="block border border-sub-blue2 rounded-full mm:text-center mm:text-2xl text-6x1 sm:text-2xl md:text-6x1 lg:text-6xl lg:leading-none text-sub-blue2 font-extrabold bg- left-to-right">
                Biografia
              </span>
              <span class="block border-sub-blue2 rounded-full py-1 text-left mm:text-left mm:text-2xl text-4x1 sm:text-2xl md:text-4x1 lg:text-4xl text-sub-white mt-5 left-to-right bg-gradient-to-r from-sub-black2">
                Introdução
              </span>
              <br />
              <p class="text-sub-white  dark:text-white left-to-right text-left mm:text-justify mm:text-sm md:text-md sm:m-auto lg:text-lg xl:text-xl ">
                  Deivison Lorenzo, casado e pai de Lorenzo, é um homem
                  empreendedor com uma mente que se destaca dos demais. Natural de
                  Pão de Açúcar/AL, é um empresário e cristão devoto, cuja paixão
                  principal reside na sua família e no mundo do empreendedorismo.
                  Atualmente, ocupa a posição de CEO na DHR Company, uma
                  prestigiada holding de investimentos.
              </p>
              {/* <span class="block border mt-5 border-sub-blue2 rounded-full mm:text-center mm:text-3xl text-6x1 sm:text-3xl md:text-6x1 lg:text-6xl lg:leading-none text-sub-blue2 font-extrabold bg- left-to-right">
                Trajetoria
              </span> */}
              <span class="block border-sub-blue2 rounded-full py-1 text-left mt-5 mm:text-left mm:text-2xl text-4x1 sm:text-2xl md:text-4x1 lg:text-4xl text-sub-white left-to-right bg-gradient-to-r from-sub-black2">
                Trajetória
              </span>
              <br />
              <p class="mt-3 text-sub-white  dark:text-white left-to-right text-left mm:text-justify mm:text-sm md:text-md lg:text-lg xl:text-xl ">
                Sua trajetória empreendedora iniciou em 2011, na cidade de
                Santos, quando adquiriu um restaurante. Em 2013, fundou a DVS
                Solutions, uma agência de publicidade de renome.
              </p>
              <p class="mt-3 text-sub-white  dark:text-white left-to-right text-left mm:text-justify mm:text-sm md:text-md lg:text-lg xl:text-xl ">
                Pouco tempo depois, em 2014, Deivison Lorenzo teve a
                oportunidade de conhecer seus parceiros, Robson Romano e Hugo
                Gomes, que juntos deram vida à MegaZap Business, uma empresa de
                grande sucesso. Sob sua liderança, a empresa conquistou uma
                posição de destaque no setor de gestão de atendimento ao
                cliente, graças à inovadora plataforma de chatbot.
              </p>
              <p class="mt-3 text-sub-white  dark:text-white left-to-right text-left mm:text-justify mm:text-sm md:text-md lg:text-lg xl:text-xl ">
                Em 2016, Deivison fundou, em colaboração com seus amigos Paulo
                Aragão e Erico Menezes, um sistema de gestão para câmaras de
                vereadores em todo o Brasil, conhecido como PED CAMARA. Em 2020,
                ele decidiu ceder sua participação no software para a empresa X3
                Tecnologia, de seu amigo Paulo Aragão, a fim de se dedicar
                inteiramente à expansão da MegaZap Business. Devido às suas
                raízes nordestinas e seu amor pelo sertão! Em 2021, Deivison
                Lorenzo abriu uma filial da MegaZap na encantadora cidade de
                Nossa Senhora da Glória. Desde sempre ele reconheceu nela
                oportunidades de crescimento e estabilidade, e agiu em pró de
                seu lugar, gerando empregos formalizados e renda para a
                população.
              </p>
              <p class="mt-3 text-sub-white  dark:text-white left-to-right text-left mm:text-justify mm:text-sm md:text-md lg:text-lg xl:text-xl ">
                Em 2023, obteve a autorização para transferir todas as operações
                de investimentos para Nossa Senhora da Glória, criando assim a
                DHR Company, uma holding de investimentos que planeja realizar
                aproximadamente 8 milhões de investimentos na cidade em um
                período de 36 meses, dos quais 4 milhões e 200 mil já foram
                investidos até o momento, em 2023. A inauguração do novo polo
                empresarial em dezembro de 2023, que servirá de cede para seu
                conglomerado empresarial e a contratação de centenas de
                funcionários, com tudo isso, posso afirmar que Deivison está
                construindo junto de sua família, funcionários, clientes, sócios
                e amigos um futuro muito promissor.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Initbio;
