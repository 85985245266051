import React, { useState } from "react";
import "../css/Cover.css";
import { TypeAnimation } from "react-type-animation";
import Tilt from "react-parallax-tilt";
import ImgEndPoint2 from "../assets/mz/dhr/ass-01.png";

function DLBiography() {
  const [scale] = useState(1.2);

  return (
    <>
      <div className="flex flex-row z-10 py-8 sm:py-2 mm:py-6 mm:h-[100vh] w-full md:py-0 mm:flex mx-auto overflow-hidden px-2 sm:px-6 lg:px-8 css-selector bg-dl-background bg-center bg-sub-black2">
        <div class="max-w-[85rem] pt-20 mx-auto px-4 mm:px-0 sm:px-6 lg:px-8 mm:pt-0 mm:m-auto">
          <div class="flex flex-col justify-end items-end mm:gap-0 gap-4 md:gap-6 xl:gap-10 md:items-end justify-items-end p-4 mm:p-0 rounded-xl">
            <div class="left-to-right mm:order-2 shadow-2x1 shadow-slate-900/50  dark:shadow-2x1 dark:shadow-slate-900">
              <h1 class="text-sub-white w-full flex justify-center bottom-0 text-3x1 mm:mt-80">
                <TypeAnimation
                  sequence={[
                    "Empreendedor",
                    900,
                    "Visionário",
                    900,
                    "Líder",
                    900,
                  ]}
                  style={{ fontSize: "3rem" }}
                  repeat={Infinity}
                  speed={50}
                />
              </h1>
              <div class="left-to-right mm:order-1 shadow-2x1 shadow-slate-900/50  dark:shadow-2x1 dark:shadow-slate-900 m-auto mt-10">
                <Tilt scale={scale} transitionSpeed={2000}>
                  <img
                    class="z-30 mm:p-1 mm:m-auto w-full"
                    src={ImgEndPoint2}
                    alt="Javascript"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DLBiography;
