import React, { useState } from "react";
import "../css/Cover.css";
import Tilt from "react-parallax-tilt";
import ImgEndPoint2 from "../assets/mz/dhr/dhr1.png";
import { Link } from "react-router-dom";

function DHRCover() {
  const [scale] = useState(1.2);

  return (
    <>
      <div className="flex flex-row z-10 py-8 sm:py-2 mm:py-6 mm:h-[100vh] w-full md:py-0 mm:flex mx-auto overflow-hidden px-2 sm:px-6 lg:px-8 css-selector bg-center bg-sub-black">
        <div class="max-w-[85rem] pt-20 mx-auto px-4 mm:px-0 sm:px-6 lg:px-8 mm:pt-0 mm:m-auto">
          <div class="grid md:grid-cols-1 mm:gap-0 gap-4 md:gap-6 xl:gap-10 md:items-center justify-items-end p-4 mm:p-0 rounded-xl">
            <div class="left-to-right mm:order-2 shadow-2x1 shadow-slate-900/50  dark:shadow-2x1 dark:shadow-slate-900">
              <Link to={"/dlbiography"}>
                <Tilt scale={scale} transitionSpeed={2000}>
                  <img
                    class="z-30 bottom-0 right-0 mm:p-1 flex justify-center w-80"
                    src={ImgEndPoint2}
                    alt="Javascript"
                  />
                </Tilt>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DHRCover;
