import React, { useState } from "react";
import "../css/Cover.css";
import Tilt from "react-parallax-tilt";
import ImgEndPoint2 from "../assets/mz/dhr/logo-endpoint.png";
function Endpoint() {
  const [scale] = useState(1.2);
  return (
    <>
      <div className="flex flex-row z-10  py-8 sm:py-2 mm:h-[100vh] sm:h-[100vh] md:h-[100vh] lg:h-[100vh] xl:h-[100vh] mm:py-6 md:py-0 mm:flex mx-auto overflow-hidden px-2 sm:px-6 lg:px-8 css-selector bg-sub-black2">
        <div class="max-w-[100rem] pt-20 mx-auto px-4 mm:px-0 sm:px-6 lg:px-8 mm:pt-0 mm:m-auto">
          <div class="grid md:grid-cols-1 mm:gap-0 gap-4 md:gap-6 xl:gap-10 md:items-center justify-items-end p-4 mm:p-0 rounded-xl ">
            <div class="left-to-right mm:order-2 shadow-2x1 shadow-slate-900/50  dark:shadow-2x1 dark:shadow-slate-900">
              <Tilt scale={scale} transitionSpeed={2000}>
                <img
                  class="z-30 bottom-0 right-0 mm:p-1"
                  src={ImgEndPoint2}
                  alt="Javascript"
                />
              </Tilt>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Endpoint;
